import './App.css';
import Header from './Header';


function App() {
  return (
    <div className="App">
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <Header/>
          </div>
        </div>
      </div>
    
    
    
     
    </div>
    
  );
  
}

export default App;
